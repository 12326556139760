<template>
  <v-container
    id="subscribe"
    tag="section"
  >
    <div>
      <v-row>
        <v-col
          cols="12"
        >
          <div 
          class="not_found content_block">
            <p>
              Unfortunately, it appears something's gone wrong and the page you are looking for can't be found at the moment. It may have been removed, renamed or been made temporarily unavailable.
            </p>
            <h3>Here are some suggestions to get where you need to go</h3>
            <ul>
              <li>You can start to pay your policy renewal by visiting <a href="/" title="Home">here</a></li>
              <li>Double check that you entered the page address correctly in the address bar</li>
              <li>Or, if you stull can't get to where you need to go, <a href="https://www.paymentshield.co.uk/contact-us" title="Paymentshield Contact us" target="_blank">get in touch</a></li>
            </ul>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            align="center"
            xs="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
            offset-xs="0"
            offset-sm="0"
            offset-md="4"
            offset-lg="4"
            offset-xl="4"
        >
          <v-btn
            x-large
            color="success"
            class="steelyGreyBtn"
            width="100%"
            to="/">
                Go back
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeSubscribe',
    components: {
      Renew: () => import('@/components/home/Renew'),
    },
  }
</script>
